import React from "react";
import classes from "./HeadingPack.module.css";

const Heading = ({ name }) => {
  return (
    <div className={classes.mainContainerChoose}>
      <div className={classes.subContainerChoose}>
        <div className={classes.cwsTextmoduleTitle}>{name}</div>
      </div>
    </div>
  );
};

export default Heading;
