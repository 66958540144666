import React from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import { Navigate } from "react-router-dom";
import MayhemStreams from "../Screen/MayhemStreams";
import CheckoutScreen from "../Screen/CheckoutScreen/CheckoutScreen";

const RoutesScreen = () => {


  return (
    <>
      <Router>
        <Routes>
          <Route
            exact
            path="/mayhemstreams"
            element={<MayhemStreams/>}
          />
          <Route path="/" element={<Navigate replace to="/mayhemstreams" />} />
          <Route
            exact
            path="/checkout"
            element={<CheckoutScreen />}
          />
        
        </Routes>
      </Router>
    </>
  );
};

export default RoutesScreen;
