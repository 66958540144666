import React from "react";
import classes from "./DiscountedPricing.module.css";

const DiscountedPricing = () => {
  return (
    <div className={classes.mainContainer} >
      <div className={classes.subContainer} >
        <div className={classes.cwsTextmoduleSubtitle}>
          Why should you buy plays from us?
        </div>
        <div className={classes.cwsTextmoduleTitle}>Distributed Traffic, Less Price  !</div>
        <div className={classes.cwsTextmoduleContentWrapper}>
          <p>
            If you are an established artist, just starting out with your first
            single or a full flex label we got the best streams/plays package plan for you
            that fits your needs. Check out our standard Mayhem streams
            packages below or contact us for any custom plans.
          </p>
          <p>
          Rather than send traffic/plays at one time, we distribute traffic throughout the month so you will receive constant traffic through out the month. Bigger the package big number you see in daily stat.
          </p>
          <p>
            We offer our services to{" "}
            <strong>independent artists, signed artists and labels</strong>. On
            this page you will be able to order organic plays
            but if you are looking to setup a bigger campaign please don’t
            hesitate to reach out to our support team who will be happy to
            assist you with any bigger projects.
          </p>
          <p>
            We pride ourselves with providing only 100% organic, real and
            engaging plays on your selected platform ranging from Apple Music, Amazon Music, Youtube and Tidal . We apply strict measures to our network of
            resources where we promote your music and have a{" "}
            <strong>
              zero tolerance policy against any artificial or bot activity
            </strong>
            .
          </p>
          <p>
            We currently work with artists and labels from all around the globe
            and we would be happy to work with you as well. Reach out to our
            support team who are <strong>available 24/7</strong> if you have any
            questions or concerns.
          </p>
        </div>
      </div>
    </div>
  );
};

export default DiscountedPricing;
