import React from "react";
import classes from "./Chooseyourplan.module.css";
import CountUp from "react-countup";

const Chooseyourplan = () => {
  return (
    <div className={classes.mainContainerChoose}>
      <div className={classes.subContainerChoose}>
        <div className={classes.cwsTextmoduleTitle}>Choose Your Plan</div>
        <div className={classes.cwsTextmodulePrice}>
          <span className={classes.topValue}>
            <CountUp end={1500} redraw={true}></CountUp>
            <span>+</span>
          </span>
          <span className={classes.lowerValue}>HAPPY ARTISTS / LABELS. </span>
        </div>
        <div className={classes.cwsTextmodulePrice}>
          <span className={classes.topValue}>
            <CountUp end={100} redraw={true}></CountUp>
            <span>%</span>
          </span>
          <span className={classes.lowerValue}>SATISFCATION</span>
        </div>
      </div>
    </div>
  );
};

export default Chooseyourplan;
