import React from "react";
import classes from "./Card.module.css";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import DoneIcon from "@mui/icons-material/Done";
import { useNavigate } from "react-router-dom";
import useWindowSize from "../../Utils/useWindowSize";
import EastIcon from "@mui/icons-material/East";

const Card = ({
  cost1,
  cost2,
  cost3,
  play1,
  play2,
  play3,
  track1,
  track2,
  track3,
}) => {
  const windowSize = useWindowSize();

  return (
    <div className={classes.mainContainerCard}>
      <div className={classes.subContainerCard}>
        <CardContainer
          backgroundColor="rgb(210 234 255)"
          color="rgb(31, 90, 188)"
          colorFont="black"
          cost={cost1}
          colorPlayArrow="black"
          colorClick="black"
          play={play1}
          topBackgroundColor="rgb(210 234 255)"
          colorTick="rgb(129 169 225)"
          track={track1}
        />

        <CardContainer
          backgroundColor="rgb(26 57 127)"
          color="white"
          colorFont="white"
          marginBottom={windowSize.width < 1199 ? null : "-45px"}
          topBackgroundColor="rgb(31 90 188)"
          cost={cost2}
          colorTick="yellow"
          colorPlayArrow="yellow"
          colorClick="yellow"
          play={play2}
          track={track2}
        />

        <CardContainer
          backgroundColor="rgb(210 234 255)"
          color="rgb(31, 90, 188)"
          colorFont="black"
          cost={cost3}
          colorPlayArrow="black"
          colorClick="black"
          play={play3}
          topBackgroundColor="rgb(210 234 255)"
          colorTick="rgb(129 169 225)"
          track={track3}
        />
      </div>
    </div>
  );
};

const CardContainer = ({
  backgroundColor,
  color,
  colorFont,
  marginBottom,
  topBackgroundColor,
  cost,
  colorTick,
  colorPlayArrow,
  colorClick,
  play,
  track,
}) => {
  const navigate = useNavigate();

  const onClickBuynow = () => {
    navigate("/checkout");
  };
  return (
    <div
      className={classes.CardContainer}
      style={{ backgroundColor: backgroundColor, marginTop: marginBottom }}
    >
      <div
        className={classes.topContainer}
        style={{ backgroundColor: topBackgroundColor }}
      >
        <div className={classes.tryUsContainer}>
          <PlayArrowIcon sx={{ fontSize: "30px", color: colorPlayArrow }} />
          <span
            className={classes.tryUsText}
            style={{ color: colorFont, fontSize: "17px" }}
          >
            {play}
          </span>
        </div>
      </div>

      <div className={classes.CardSubContainer}>
        <div className={classes.cost} style={{ color: color }}>
          {cost === "CUSTOM" ? (
            cost
          ) : (
            <>
              <sup style={{fontSize:'30px',marginBottom:"8px",fontFamily:"unset"}}>$</sup>
              {cost}
            </>
          )}
        </div>
        <div className={classes.tickContainer}>
          <div className={classes.tickSubContainer}>
            {" "}
            <DoneIcon sx={{ color: colorTick }} />
            <span
              style={{ fontSize: "15px", marginLeft: "15px", color: colorFont }}
            >
              <strong style={{ color: colorFont }}>{track}</strong>
            </span>
          </div>
          <div className={classes.line}></div>
        </div>
        <div className={classes.tickContainer}>
          <div className={classes.tickSubContainer}>
            {" "}
            <DoneIcon sx={{ color: colorTick }} />
            <span style={{ fontSize: "15px", marginLeft: "15px" }}>
              <strong style={{ color: colorFont }}>
                Over Apple Music, Amazon Music, Youtube Music and Tidal
              </strong>
            </span>
          </div>
          <div className={classes.line}></div>
        </div>
        {cost === "CUSTOM" ? (
          <div className={classes.tickContainer}>
            <div className={classes.tickSubContainer}>
              {" "}
              <DoneIcon sx={{ color: colorTick }} />
              <span style={{ fontSize: "15px", marginLeft: "15px" }}>
                <strong style={{ color: colorFont }}>
                  Plays from listeners in your music genre
                </strong>
              </span>
            </div>
            <div className={classes.line}></div>
          </div>
        ) : null}
 
        {/* <div className={classes.tickContainer}>
          <div className={classes.tickSubContainer}>
            {" "}
            <DoneIcon sx={{ color: colorTick }} />
            <span
              style={{ fontSize: "16px", marginLeft: "15px", color: colorFont }}
            >
              <strong style={{ color: colorFont }}>Dedicated</strong> account
              manager
            </span>
          </div>
          <div className={classes.line}></div>
        </div>
        <div className={classes.tickContainer}>
          <div className={classes.tickSubContainer}>
            {" "}
            <DoneIcon sx={{ color: colorTick }} />
            <span
              style={{ fontSize: "16px", marginLeft: "15px", color: colorFont }}
            >
              Plays from listeners in{" "}
              <strong style={{ color: colorFont }}> your music genre</strong>
            </span>
          </div>
          <div className={classes.line}></div>
        </div>
        <div className={classes.tickContainer}>
          <div className={classes.tickSubContainer}>
            {" "}
            <DoneIcon sx={{ color: colorTick }} />
            <span style={{ fontSize: "16px", marginLeft: "15px" }}>
              <strong style={{ color: colorFont }}>
                100% Monetize-able Plays
              </strong>
            </span>
          </div>
          <div className={classes.line}></div>
        </div> */}

        {cost === "CUSTOM" ? null : (
          <div style={{ paddingTop: "20px" }}>
            <div className={classes.lookingFont} style={{ color: colorFont }}>
              Looking for wholesale pricing?
            </div>
            <div className={classes.lookingFont} style={{ color: colorFont }}>
              Check out our Self-Service Platform:
            </div>
            <div className={classes.clickFont} style={{ color: colorClick }}>
              click here.
            </div>
          </div>
        )}

        <div className={classes.buyNowButtonContainer}>
          <button
            onClick={() => onClickBuynow()}
            className={
              cost === "CUSTOM" ? classes.contactUsButton : classes.buyNowButton
            }
          >
            BUY NOW{" "}
            {cost === "CUSTOM" ? (
              <EastIcon className={classes.iconArrow} />
            ) : null}
          </button>
        </div>
        {cost === "CUSTOM" ? (
          <div style={{ paddingTop: "20px" }}>
            <div className={classes.lookingFont} style={{ color: colorFont }}>
              Looking for wholesale pricing?
            </div>
            <div className={classes.lookingFont} style={{ color: colorFont }}>
              Check out our Discord:
            </div>
            {/* <div className={classes.clickFont} style={{ color: colorClick }}>
              click here.
            </div> */}
          </div>
        ) : (
          <div>
            <p className={classes.termText} style={{ color: colorFont }}>
              By clicking you’re accepting Terms of use.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Card;
