import React, { useState } from "react";
import classes from "./MonthlyListner.module.css";
import image2 from "../../Assets/arrowNew.JPG";
import image1 from "../../Assets/headphone.JPG";
import image3 from "../../Assets/dil.JPG";
import image4 from "../../Assets/nishan2.JPG";
import image11 from "../../Assets/heartSecond.JPG";
import image12 from "../../Assets/arrowSecond.JPG";
import image13 from "../../Assets/headphoneSecond.JPG";
import image14 from "../../Assets/nishanSecond.JPG";
import { useNavigate } from "react-router-dom";
import useWindowSize from "../../Utils/useWindowSize";

const MonthlyListner = () => {
  const [hover, setHover] = useState(true);
  const [hover2, setHover2] = useState(true);
  const [hover3, setHover3] = useState(true);
  const [hover4, setHover4] = useState(true);

  const navigate = useNavigate();
  const windowSize = useWindowSize();

  const onClickBuynow = () => {
    navigate("/checkout");
  };
  return (
    <>
      {windowSize.width > 1199 ? (
        <div className={classes.mainContainer}>
          {hover ? (
            <div onMouseOver={() => setHover(false)} className={classes.card}>
              <div className={classes.imageContainer}>
                <img src={image1} />
              </div>
              <div className={classes.headingContainer}>Monthly Listeners</div>
              <div className={classes.text}>
                Your popularity is determined by the number of people who listen
                to it. You can increase your monthly plays in hours when you buy
                US monthly listeners from us.
              </div>
              {/* <div className={classes.chooseText}>CHOOSE PACKAGE</div> */}
            </div>
          ) : (
            <div
              className={classes.cardNew}
              onMouseLeave={() => setHover(true)}
            >
              <div className={classes.imageContainer}>
                <img
                  src={image13}
                  style={{
                    borderRadius: "17px",
                    objectFit: "cover",
                    width: "100%",
                  }}
                />
              </div>
              <div className={classes.comingSoon}>Coming Soon</div>

              <div className={classes.headingContainer2}>Monthly Listeners</div>
              <div className={classes.text}>
                Your popularity is determined by the number of people who listen
                to it. You can increase your monthly plays in hours when you buy
                US monthly listeners from us.
              </div>
              {/* <button
                className={classes.buyNowButton}
                style={{
                  backgroundColor: "gray",
                  borderColor: "gray",
                  cursor: "no-drop",
                }}
              >
                Choose Package
              </button> */}
            </div>
          )}

          {hover2 ? (
            <div className={classes.card} onMouseOver={() => setHover2(false)}>
              <div className={classes.imageContainer}>
                <img src={image2} />
              </div>
              <div className={classes.headingContainer}>Plays</div>
              <div className={classes.text}>
                Getting noticed on Youtube and Apple Music is not easy, especially if you don’t
                have many plays and followers.
              </div>
              {/* <div className={classes.chooseText}>CHOOSE PACKAGE</div> */}
            </div>
          ) : (
            <div
              onMouseLeave={() => setHover2(true)}
              className={classes.cardNew}
            >
              <div className={classes.imageContainer}>
                <img
                  src={image12}
                  style={{
                    borderRadius: "17px",
                    objectFit: "cover",
                    width: "100%",
                  }}
                />
              </div>
              <div className={classes.headingContainer2}>Plays</div>
              <div className={classes.text}>
                Getting noticed on music streaming services is not easy, especially if you don’t
                have many plays and followers.
              </div>
              <button
                onClick={() => onClickBuynow()}
                className={classes.buyNowButton}
                style={{
                  backgroundColor: "rgba(31, 90, 188, 1)",
                  borderColor: "rgba(31, 90, 188, 1)",
                }}
              >
                Choose Package
              </button>
            </div>
          )}
          {hover3 ? (
            <div className={classes.card} onMouseOver={() => setHover3(false)}>
              <div className={classes.imageContainer}>
                <img src={image4} />
              </div>
              <div className={classes.headingContainer}>Followers</div>
              <div className={classes.text}>
                It’s no secret that amount of followers you have on Spotify
                represents your overall image as an artist.
              </div>
              {/* <div className={classes.chooseText}>CHOOSE PACKAGE</div> */}
            </div>
          ) : (
            <div
              className={classes.cardNew}
              onMouseLeave={() => setHover3(true)}
            >
              <div className={classes.imageContainer}>
                <img
                  src={image14}
                  style={{
                    borderRadius: "17px",
                    objectFit: "cover",
                    width: "100%",
                  }}
                />
              </div>
              <div className={classes.comingSoon}>Coming Soon</div>
              <div className={classes.headingContainer2}>Followers</div>
              <div className={classes.text}>
                It’s no secret that amount of followers you have on Spotify
                represents your overall image as an artist.
              </div>
              {/* <button
                className={classes.buyNowButton}
                style={{
                  backgroundColor: "gray",
                  borderColor: "gray",
                  cursor: "no-drop",
                }}
              >
                Choose Package
              </button> */}
            </div>
          )}

          {hover4 ? (
            <div className={classes.card} onMouseOver={() => setHover4(false)}>
              <div className={classes.imageContainer}>
                <img src={image3} />
              </div>
              <div className={classes.headingContainer}>Saves</div>
              <div className={classes.text}>
                If you’ve decided to set on Spotify and now trying to acquire
                popularity and love from multiple users, what you’re going to
                need is some help from us.
              </div>
              {/* <div className={classes.chooseText}>CHOOSE PACKAGE</div> */}
            </div>
          ) : (
            <div
              className={classes.cardNew}
              onMouseLeave={() => setHover4(true)}
            >
              <div className={classes.imageContainer}>
                <img
                  src={image11}
                  style={{
                    borderRadius: "17px",
                    objectFit: "cover",
                    width: "100%",
                  }}
                />
              </div>
              <div className={classes.comingSoon}>Coming Soon</div>
              <div className={classes.headingContainer2}>Saves</div>
              <div className={classes.text}>
                If you’ve decided to set on Apple Music,Youtube, Tidal and Amazon Music and now trying to acquire
                popularity and love from multiple users, what you’re going to
                need is some help from us.
              </div>
              {/* <button
                className={classes.buyNowButton}
                style={{
                  backgroundColor: "gray",
                  borderColor: "gray",
                  cursor: "no-drop",
                }}
              >
                Choose Package
              </button> */}
            </div>
          )}
        </div>
      ) : (
        <div className={classes.mainContainer}>
          <div className={classes.cardNew}>
            <div className={classes.imageContainer}>
              <img
                src={image13}
                style={{
                  borderRadius: "17px",
                  objectFit: "cover",
                  width: "100%",
                }}
              />
            </div>
            <div className={classes.comingSoon}>Coming Soon</div>

            <div className={classes.headingContainer2}>Monthly Listeners</div>
            <div className={classes.text}>
              Your popularity is determined by the number of people who listen
              to it. You can increase your monthly plays in hours when you buy
              US monthly listeners from us.
            </div>
            {/* <button
              className={classes.buyNowButton}
              style={{
                backgroundColor: "gray",
                borderColor: "gray",
                cursor: "no-drop",
              }}
            >
              Choose Package
            </button> */}
          </div>
          <div className={classes.cardNew}>
            <div className={classes.imageContainer}>
              <img
                src={image12}
                style={{
                  borderRadius: "17px",
                  objectFit: "cover",
                  width: "100%",
                }}
              />
            </div>
            <div className={classes.comingSoon}></div>

            <div className={classes.headingContainer2}>Plays</div>
            <div className={classes.text}>
              Getting noticed on Streaming service is not easy, especially if you don’t
              have many plays and followers.
            </div>
            {/* <button
              onClick={() => onClickBuynow()}
              className={classes.buyNowButton}
              style={{
                backgroundColor: "rgba(31, 90, 188, 1)",
                borderColor: "rgba(31, 90, 188, 1)",
              }}
            >
              Choose Package
            </button> */}
          </div>
          <div className={classes.cardNew}>
            <div className={classes.imageContainer}>
              <img
                src={image14}
                style={{
                  borderRadius: "17px",
                  objectFit: "cover",
                  width: "100%",
                }}
              />
            </div>
            <div className={classes.comingSoon}>Coming Soon</div>
            <div className={classes.headingContainer2}>Followers</div>
            <div className={classes.text}>
              It’s no secret that amount of followers you have on Spotify
              represents your overall image as an artist.
            </div>
            {/* <button
              className={classes.buyNowButton}
              style={{
                backgroundColor: "gray",
                borderColor: "gray",
                cursor: "no-drop",
              }}
            >
              Choose Package
            </button> */}
          </div>
          <div className={classes.cardNew}>
            <div className={classes.imageContainer}>
              <img
                src={image11}
                style={{
                  borderRadius: "17px",
                  objectFit: "cover",
                  width: "100%",
                }}
              />
            </div>
            <div className={classes.comingSoon}>Coming Soon</div>
            <div className={classes.headingContainer2}>Saves</div>
            <div className={classes.text}>
              If you’ve decided to set on Apple Music, Amazon Music, Youtube Music and now trying to acquire
              popularity and love from multiple users, what you’re going to need
              is some help from us.
            </div>
            {/* <button
              className={classes.buyNowButton}
              style={{
                backgroundColor: "gray",
                borderColor: "gray",
                cursor: "no-drop",
              }}
            >
              Choose Package
            </button> */}
          </div>
        </div>
      )}
    </>
  );
};

export default MonthlyListner;
