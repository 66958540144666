import React from "react";
import classes from "./CheckoutScreen.module.css";
import Button from "@mui/material/Button";

const CheckoutScreen = () => {

  return (
    <div className={classes.mainContainer}>
      <div className={classes.subContainer}>
        <h2 className={classes.text}>Payment Gateway Coming Soon </h2>
        <p> While we are working on our Payment Gateway, Please join our Discord server and request the service from the farmer.</p>
        <h3>Currently, we accept Venmo, CashApp, Paypal and Bitcoin</h3>
        {/* <h3 className={classes.text}>
          If you want to buy bulk or customized order join the discord server.
        </h3> */}

  
        <a className={classes.buyNowButton} href="https://discord.gg/fXDHFGbufk" target="_blank" rel="noreferrer">Join Now</a>
        </div>
       
      
    </div>
  );
};

export default CheckoutScreen;
