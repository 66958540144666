// import Header from "./Components/Header";
import Header from "../Components/Header/Header";
import DiscountedPricing from "../Components/DiscountedPricing/DiscountedPricing";
import Chooseyourplan from "../Components/ChooseYourPlan/Chooseyourplan";
import Card from "../Components/Card/Card";
import OurProcess from "../Components/OurProcess/OurProcess";
import Myfooter from "../Components/Footer/Footer";
import MonthlyListner from "../Components/MonthlyListner/MonthlyListner";
import Heading from "../Components/HeadingPack/HeadingPack";

function MayhemStreams() {
  return (
    <>
      <Header />
      <DiscountedPricing />
      <Chooseyourplan />
      <Heading name="Boomer Pack" />
      <Card
        cost1="10.99"
        cost2="49.99"
        cost3="99.99"
        play1="1 month (traffic/plays) 1 song"
        play2="1month (traffic/plays) 5 songs"
        play3="1month (traffic/plays) 10 songs"
        track1="+3000 Streams"
        track2="+25000 plays distributed among tracks"
        track3="+50000 plays distributed among tracks"
      />
      <Heading name="Rockstar Pack" />
      <Card
        cost1="45.99"
        cost2="CUSTOM"
        cost3="224.99"
        play1="1month (traffic/plays) 1 song"
        play2="MORE THAN 500K PLAYS?"
        play3="1 month (traffic/plays) 5 songs"
        track1="+10000 plays"
        track2="Get Real plays with constant traffic"
        track3="+50000 plays distributed among tracks"
      />
      {/* <Card
        cost1="45.99"
        cost2="xyz"
        cost3="224.99"
        play1="1month (traffic/plays) 1 song"
        play2=""
        play3="1 month (traffic/plays) 5 songs"
        track1="+10000 plays"
        track2=""
        track3="+50000 plays distributed among tracks"
      /> */}

      {/* <Card
        cost1="179.99"
        cost2="CUSTOM"
        cost3="299.99"
        play1="250,000 PLAYS"
        play2="MORE THAN 500K PLAYS?"
        play3="500,000 PLAYS"
      /> */}
      {/* <OurProcess/> */}
      <MonthlyListner />
      {/* <Myfooter/> */}
    </>
  );
}

export default MayhemStreams;
