import React from "react";
import classes from "./Header.module.css";
import HouseIcon from "@mui/icons-material/House";
import DiamondIcon from "@mui/icons-material/Diamond";
import StarIcon from "@mui/icons-material/Star";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SearchIcon from "@mui/icons-material/Search";
import { fontSize } from "@mui/system";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { BorderBottom } from "@mui/icons-material";
import DomainIcon from "@mui/icons-material/Domain";
import logo from "../../Assets/logoNewImage.png";

const Header = () => {
  return (
    <div className={classes.App}>
      <div className={classes.header}>
        <div className={classes.topBarWrapper}>
          <div className={classes.headerTop}>
            <div className={classes.topBarLinksWrapper}>
              <DomainIcon
                sx={{ color: "rgb(155 167 223)", marginLeft: "30px" }}
              />

              <a
                class="top-bar-box-text"
                // href="https://portal.streamingfamous.com/"
              >
                <span className={classes.textLooking}>
                  Looking for wholesale pricing? Check out Discord
                </span>
              </a>
            </div>
          </div>
          <div className={classes.secondContainer}>
            <div className={classes.secondSubContainer}>
              {/* <img
                src="https://streamingfamous.com/wp-content/uploads/2019/10/streamingfamous-white-big-fix_00dc00290_2386.png"
                data-at2x="https://streamingfamous.com/wp-content/uploads/2019/10/streamingfamous-white-big-fix_00dc00290_2386@2x.png"
                alt="streamingfamous-white-big-fix"
                class="logo-default"
              /> */}
              <div className={classes.mayhemContainer}>
              <img  src={logo} />
              </div>
              {/* <h3 className={classes.mayhemContainer}>Mayhem streams farm</h3> */}
              <div className={classes.secondMainContainer}>
                <div className={classes.homeContainer}>
                  {/* <HouseIcon />
                  <span style={{ marginLeft: "2px" }}>Home</span>
                </div>
                <div className={classes.homeContainer}>
                  <DiamondIcon />
                  <span style={{ marginLeft: "2px" }}>The Portal</span>
                </div>
                <div className={classes.homeContainer}>
                  <StarIcon />
                  <span style={{ marginLeft: "2px" }}>Packages</span>
                </div>
                <div
                  className={classes.homeContainer}
                  style={{
                    borderBottomColor: "yellow",
                    borderBottomWidth: "2px",
                  }}
                >
                  <PlayArrowIcon />
                  <span
                    style={{
                      marginLeft: "2px",
                      borderBottomColor: "yellow",
                      borderBottomWidth: "2px",
                    }}
                  >
                    Buy Plays
                  </span>
                  <ExpandMoreIcon sx={{ color: "grey" }} />
                </div>
                <div className={classes.homeContainer}>Buy Followers</div>
                <div className={classes.homeContainer}>Buy Saves</div>
                <div>
                  {" "}
                  <SearchIcon sx={{ color: "white" }} /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
